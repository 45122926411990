<template>
  <v-card outlined :loading="loadings.mainCard">
    <v-card-title>
      {{ currentRouteName }}

      <v-spacer></v-spacer>
      <v-btn depressed color="info" to="/company/create"> Create Order </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item">
          <v-card flat>
            <v-card-text>
              <v-data-table
                v-if="tab == 0"
                key="tab_datatable_suppliers_1"
                :headers="headers"
                :items="orders"
              ></v-data-table>
              <v-container
                v-if="tab == 1 && currentUserType.key != 'tenant_user'"
              >
                <v-select
                  @change="onTenantChange"
                  hide-details=""
                  :items="tenants"
                  item-text="name"
                  item-value="id"
                  label="Select Tenant"
                  outlined
                ></v-select>
              </v-container>
              <v-data-table
                v-if="tab == 1"
                key="tab_datatable_suppliers_2"
                :headers="headersbyTenant"
                :items.sync="ordersByTenant"
              ></v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      tab: null,
      items: ["Orders Distribuye", "Orders Inventory"],
      loadings: {
        mainCard: false,
      },
      footer: {},
      headersbyTenant: [
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Invoice Number",
          value: "invoice_number",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Wharehouse",
          value: "warehouse_id",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Status",
          value: "status",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Supplier",
          value: "supplier_id",
        },
      ],
      headers: [
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Order Number",
          value: "orderNumber",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Supplier",
          value: "supplierId",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Company",
          value: "companyId",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Location",
          value: "locationId",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Total Cost",
          value: "totalCost",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Status",
          value: "status",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "orders",
      "currentUserType",
      "tenants",
      "ordersByTenant",
      "currentTenant",
    ]),
    currentRouteName() {
      return this.$route.name;
    },
    distribuyeOrders() {
      const self = this;
      if (self.orders && !self.orders) return [];
      return self.orders.distribuye;
    },
    inventoryOrders() {
      const self = this;
      if (!self.orders.inventory) return [];
      return self.orders.inventory;
    },
  },
  methods: {
    ...mapActions(["getAllOrders", 'getAllTenants', 'getOrdersByTenant']),
  },
  async created() {
    const self = this;
    self.loadings.mainCard = true;
    self.getAllOrders().finally(() => {
      self.loadings.mainCard = false;
    });
    await self.getAllTenants();
    if (self.currentUserType.key == "tenant_user") {
      self.getOrdersByTenant(self.currentTenant.id);
    }
  },
};
</script>

<style>
</style>
